import Messages from '@core/scripts/components/messages';
import MessageBroker from '@bonprix/pattern-library/components/message-broker';

const APP_BANNER_VISIBILITY_CLASS = '-banner-is-opened';
const BANDEROLE_VISIBILITY_CLASS = '-banderole-is-opened';
const APP_BANNER_EXISTS_CLASS = '-banner-exists';

MessageBroker.subscribe(Messages.APP_BANNER.CLOSE, onRemoveAppBanner);

const navigationMessages = [];
let inNavigation = false;

if (document.body.classList.contains(APP_BANNER_VISIBILITY_CLASS)) {
    navigationMessages.push(MessageBroker.subscribe(Messages.NAVIGATION_ACCORDION.OPENED, hideAppBannerForNaviagtion));
    navigationMessages.push(MessageBroker.subscribe(Messages.NAVIGATION_ACCORDION.CLOSED, restoreAppBannerVisibilityPostNavigation));
}

if (document.body.classList.contains(BANDEROLE_VISIBILITY_CLASS)) {
    MessageBroker.subscribe(Messages.NAVIGATION_ACCORDION.OPENED, hideBanderolForNaviagtion);
    MessageBroker.subscribe(Messages.NAVIGATION_ACCORDION.CLOSED, restoreBanderolVisibilityPostNavigation);
}

function onRemoveAppBanner() {
    document.body.classList.remove(APP_BANNER_VISIBILITY_CLASS);
    document.body.classList.remove(APP_BANNER_EXISTS_CLASS);
    navigationMessages.forEach((message) => MessageBroker.unsubscribe(message));
}

function toggleAppBannerVisibility() {
    if (inNavigation) {
        return;
    }
    document.body.classList.add(APP_BANNER_VISIBILITY_CLASS);
}
function toggleBanderoleVisibility() {
    if (inNavigation) {
        return;
    }
        document.body.classList.add(BANDEROLE_VISIBILITY_CLASS);
}

function hideAppBannerForNaviagtion() {
    inNavigation = true;
    document.body.classList.remove(APP_BANNER_VISIBILITY_CLASS);
}

function hideBanderolForNaviagtion() {
    inNavigation = true;
    document.body.classList.remove(BANDEROLE_VISIBILITY_CLASS);
}

function restoreAppBannerVisibilityPostNavigation() {
    inNavigation = false;
    toggleAppBannerVisibility();
}

function restoreBanderolVisibilityPostNavigation() {
    inNavigation = false;
    toggleBanderoleVisibility();
}
